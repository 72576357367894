<template>
  <div>
    <b-form
      :style="{height: trHeight}"
      class="repeater-form"
      @submit.prevent="repeateAgain"
    >
      <validation-observer
        ref="foundersInfoRules"
        tag="form"
      >
        <!-- Row Loop -->
        <b-row
          v-for="(item, index) in items"
          :key="item"
          ref="row"
        >

          <!-- Item Name -->
          <b-col>
            <validation-provider
              #default="{ errors }"
              name="Founder Name"
              rules="required"
            >
              <b-form-group
                :label-for="'founder-name-'+index"
                label="Full Name *"
              >
                <b-form-input
                  :id="'founder-name-'+index"
                  v-model="item.name"
                  :state="errors.length > 0 ? false:null"
                  placeholder="Founder's Name"
                  required
                  type="text"
                  @input="notifyParent"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Remove Button -->
          <b-col
            v-if="items.length > 1"
            class="mb-50"
            md="auto"
          >
            <b-button
              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
              class="mt-0 mt-2"
              variant="outline-danger"
              @click="removeItem(index)"
            >
              <feather-icon
                class="mr-25"
                icon="XIcon"
              />
              <span>Remove Founder</span>
            </b-button>
          </b-col>
          <b-col md="6">
            <b-form-group
              :label-for="'founder-din-'+index"
              label="DIN"
            >
              <b-form-input
                :id="'founder-din-'+index"
                v-model="item.din_number"
                placeholder="Founder's DIN"
                required
                type="text"
                @input="notifyParent"
              />
            </b-form-group>
          </b-col>

          <b-col md="6">
            <validation-provider
              #default="{ errors }"
              name="Email"
              rules="required"
            >
              <b-form-group
                :label-for="'founder-email-'+index"
                label="Email *"
              >
                <b-form-input
                  :id="'founder-email-'+index"
                  v-model="item.email"
                  :state="errors.length > 0 ? false:null"
                  placeholder="Founder's Email"
                  required
                  type="email"
                  @input="notifyParent"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="6">
            <b-form-group
              :label-for="'founder-phone-'+index"
              label="Phone"
            >
              <b-form-input
                :id="'founder-phone-'+index"
                v-model="item.phone"
                placeholder="Founder's Phone"
                required
                type="tel"
                @input="notifyParent"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <validation-provider
              #default="{ errors }"
              name="Designation"
              rules="required"
            >
              <b-form-group
                :label-for="'founder-designation-'+index"
                label="Designation *"
              >
                <b-form-input
                  :id="'founder-designation-'+index"
                  v-model="item.designation"
                  :state="errors.length > 0 ? false:null"
                  placeholder="Designation in Startup"
                  required
                  type="text"
                  @input="notifyParent"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="6">
            <b-form-group
              :label-for="'founder-equity-'+index"
              label="Equity %"
            >
              <b-form-input
                :id="'founder-equity-'+index"
                v-model="item.equity_holding"
                placeholder="Equity % holding in startup"
                required
                type="number"
                @input="notifyParent"
              />
            </b-form-group>
          </b-col>

          <b-col md="12">
            <hr>
          </b-col>
        </b-row>
      </validation-observer>
    </b-form>
    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      @click="repeateAgain"
    >
      <feather-icon
        icon="PlusIcon"
        class="mr-25"
      />
      <span>Add Founder</span>
    </b-button>
  </div>
</template>

<script>
import {
  BButton, BCol, BForm, BFormGroup, BFormInput, BRow,
} from 'bootstrap-vue'
import { heightTransition } from '@core/mixins/ui/transition'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
  },
  mixins: [heightTransition],
  props: ['founders'],
  data() {
    return {
      items: this.founders.length ? this.founders : [{
        name: null,
        din_number: null,
        designation: null,
        email: null,
        phone: null,
        equity_holding: null,
      }],
    }
  },
  methods: {
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.foundersInfoRules.validate()
          .then(success => {
            if (success) {
              resolve(true)
            } else {
              reject()
            }
          })
      })
    },
    repeateAgain() {
      this.items.push({
        name: null,
        din_number: null,
        designation: null,
        email: null,
        phone: null,
        equity_holding: null,
      })

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    removeItem(index) {
      this.items.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    notifyParent() {
      this.$emit('input', this.items)
    },
  },
}
</script>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
</style>
